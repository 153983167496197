import React from "react";
import Header from '../Login/Header';

function RefundPolicy() {
  return (
      <>
          <Header/>
          <div className="policies-container">
              <div className="card">
                  <h1 className="title">Refund Policy</h1>
                  <p className="text">
                      We regret to inform you that we do not provide refunds for any
                      services or products offered. All sales are final and non-refundable.
                  </p>
                  <p className="text">
                      We appreciate your understanding and encourage you to review all
                      services and policies thoroughly before making any purchase or
                      enrollment.
                  </p>
                  <p className="text">
                      If you have any questions or concerns, please do not hesitate to
                      contact our support team for further assistance.
                  </p>
              </div>
          </div>
      </>
  );
}

export default RefundPolicy;
