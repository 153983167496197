import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';

const ConfirmationModal = ({CloseAreYou, action, message, actionData}) => {


    const handleConfirm = async () => {
        await action(actionData)
        await CloseAreYou()
    };

    return (
        <>
            <Modal show={true} onHide={CloseAreYou} centered style={{textAlign: "center"}}>
                <Modal.Header style={{justifyContent: "center"}}>
                    <Modal.Title style={{fontSize: "17px", color: "gray"}}>{message}</Modal.Title>
                </Modal.Header>
                <div>
                    <div>
                        <Button variant="danger" onClick={handleConfirm}
                                style={{backgroundColor: "white", color: "rgb(33, 150, 243)"}}>
                            Yes, I'm Sure
                        </Button>
                    </div>
                    <div style={{borderTop: "1px solid lightgray"}}>
                        <Button variant="secondary" onClick={CloseAreYou}
                                style={{backgroundColor: "white", color: "red"}}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ConfirmationModal;
