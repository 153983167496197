import React, {startTransition, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../auth/Auth";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Header() {
    const navigate = useNavigate();
    const {logoutUser, successModal} = useAuth();
    const {user, selectedCenter, setSelectedCenter} = useAuth();
    const userType = user.type;
    console.log("userrrrrrrrrrrrrrrr", user)

    const handleCenterChange = (id, name) => {
        setSelectedCenter({
            center_id: id,
            center_name: name,
        });
        successModal(
            "Changed",
            `Now, ERP will fetch data for ${name}  as the default center!`,
        );
        navigate("/daily_update");
    };

    const handleLogout = (value) => {
        if (value === "logout") {
            startTransition(() => {
                logoutUser();
            });
        }
    };

    return (
        <>
            {user?.isAuthenticated ? (
                <Navbar expand="lg" className="custom-navbar" id={"nav_id"}>
                    <Container className="nav_padding">
                        <Navbar.Brand as={Link} to="/daily_update">
            <span style={{color: "rgb(155,81,224)", fontSize: "22px"}}>
              MickyTots
            </span>
                            <span
                                style={{fontSize: "12px", fontWeight: "500", color: "yellow"}}
                            >
              {" "}
                                ERP
            </span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                                    <Nav.Link as={Link} to="/daily_update">
                                        Daily Updates
                                    </Nav.Link>
                                    {/*<Nav.Link as={Link} to="/salesDashboard">Sales Dashboard</Nav.Link>*/}
                                    {userType === "admin" ? (
                                        <Nav.Link as={Link} to="/admission_dashboard">
                                            Admissions Dashboard
                                        </Nav.Link>
                                    ) : null}
                                    <Nav.Link as={Link} to="/privacy-policy">
                                        Privacy Policy
                                    </Nav.Link>
                                     <Nav.Link as={Link} to="/terms-&-conditions">
                                        Terms & Conditions
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/refund-policy">
                                        Refund Policy
                                    </Nav.Link>
                                </NavDropdown>
                                <NavDropdown title="Childrens" id="basic-nav-dropdown">
                                    <Nav.Link as={Link} to="/all_children">
                                        {" "}
                                        Active + Joining + Paused Children
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/customer_request">
                                        {" "}
                                        Customer Request
                                    </Nav.Link>
                                    {/*<Nav.Link as={Link} to="/admission_numbers">*/}
                                    {/*    {" "}*/}
                                    {/*    Attrition &amp; suspended*/}
                                    {/*</Nav.Link>*/}
                                    <Nav.Link as={Link} to="/child_attendance_report">
                                        {" "}
                                        Attendance Report
                                    </Nav.Link>
                                    {/*<Nav.Link as={Link} to="/revenueReport">Revenue Report</Nav.Link>*/}
                                    {/*<Nav.Link as={Link} to="/parentOrientation">Parent Orientation Details</Nav.Link>*/}
                                    {/*<Nav.Link as={Link} to="/visitSchedules">Visit Schedules</Nav.Link>*/}
                                    {/*<Nav.Link as={Link} to="/dayCareRegistration">Register Emergency Day-care</Nav.Link>*/}
                                    {/*<Nav.Link as={Link} to="/care_calls_archive"> Care Call Archive</Nav.Link>*/}
                                    {/*<Nav.Link as={Link} to="/emergencyDaycare"> Emergency Day-care</Nav.Link>*/}
                                    {userType === "admin" ? (
                                        <>
                                            {/*<Nav.Link as={Link} to="/oneTimeCharges">Apply OneTime Charges</Nav.Link>*/}
                                            <Nav.Link as={Link} to="/invoices">
                                                Invoices
                                            </Nav.Link>
                                            {/*<Nav.Link as={Link} to="/collectionReport">Collection Status</Nav.Link>*/}
                                            {/*<Nav.Link as={Link} to="/collectionReport">Collection Status</Nav.Link>*/}
                                        </>
                                    ) : null}
                                </NavDropdown>
                                <NavDropdown title="Inbox" id="basic-nav-dropdown">
                                    <Nav.Link as={Link} to="/announcements">
                                        Events &amp; Outdoor Activities
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/fee_card">
                                        {" "}
                                        Fee Card{" "}
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/fee_calculator">
                                        {" "}
                                        Fee Calculator
                                    </Nav.Link>
                                    {/*<Nav.Link as={Link} to="/invoice">*/}
                                    {/*    {" "}*/}
                                    {/*    Invoice*/}
                                    {/*</Nav.Link>*/}
                                    <Nav.Link as={Link} to="/fee_Structure">
                                        {" "}
                                        Fee Structure{" "}
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/food_menu">
                                        {" "}
                                        Food Menu
                                    </Nav.Link>
                                    {/*<Nav.Link as={Link} to="/plannerList"> Planner List</Nav.Link>*/}
                                    <Nav.Link as={Link} to="/live_streaming">
                                        Live Streaming
                                    </Nav.Link>
                                    {/*<Nav.Link as={Link} to="/demoAcc"> Demo Accounts</Nav.Link>*/}
                                    {/*<Nav.Link as={Link} to="/sharedDocs">Shared Documents</Nav.Link>*/}
                                </NavDropdown>
                                {/*conversation, child measurement, children medical records*/}
                                <NavDropdown
                                    title="Supermam App"
                                    id="basic-nav-dropdown"
                                    id="inbox-dropdown"
                                >
                                    <Nav.Link as={Link} to="/attendance">
                                        {" "}
                                        Attendance
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/measurement">
                                        {" "}
                                        Child Measurement
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/medical_records">
                                        {" "}
                                        Children Medical Records
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/conversation">
                                        {" "}
                                        Conversation
                                    </Nav.Link>
                                </NavDropdown>
                                {userType === "admin" ? (
                                    <>
                                        <NavDropdown title="Employee" id="basic-nav-dropdown">
                                            <Nav.Link as={Link} to="/employee">
                                                {" "}
                                                Manage Employee
                                            </Nav.Link>
                                            {/*<Nav.Link as={Link} to="/exEmployees">*/}
                                            {/*    {" "}*/}
                                            {/*    Ex Employee*/}
                                            {/*</Nav.Link>*/}
                                            {/*<Nav.Link as={Link} to="/employeeAttendance">*/}
                                            {/*    {" "}*/}
                                            {/*    Center Daily Attendance*/}
                                            {/*</Nav.Link>*/}
                                            {/*<Nav.Link as={Link} to="/supermam">*/}
                                            {/*    {" "}*/}
                                            {/*    Supermams Availability Report*/}
                                            {/*</Nav.Link>*/}
                                            {/*<Nav.Link as={Link} to="/adult_child_ratio">*/}
                                            {/*    {" "}*/}
                                            {/*    Adult Child Ratio{" "}*/}
                                            {/*</Nav.Link>*/}
                                        </NavDropdown>
                                        <NavDropdown title="Admin" id="basic-nav-dropdown">
                                            <Nav.Link as={Link} to="/admin">
                                                {" "}
                                                Manage Admin
                                            </Nav.Link>
                                            <Nav.Link as={Link} to="/childAdmission">
                                                Child Admission
                                            </Nav.Link>
                                            <Nav.Link as={Link} to="/centers">
                                                Centers
                                            </Nav.Link>
                                            <Nav.Link as={Link} to="/programs">
                                                {" "}
                                                Programs
                                            </Nav.Link>
                                            <Nav.Link as={Link} to="/classrooms">
                                                {" "}
                                                Classrooms
                                            </Nav.Link>
                                            <Nav.Link as={Link} to="/holidays">
                                                Holidays{" "}
                                            </Nav.Link>
                                            <Nav.Link as={Link} to="/blog">
                                                Blog{" "}
                                            </Nav.Link>
                                            <Nav.Link as={Link} to="/faq">
                                                FAQ{" "}
                                            </Nav.Link>
                                        </NavDropdown>
                                    </>
                                ) : null}
                            </Nav>
                            <Nav className="nav-profile">
                                <NavDropdown
                                    title={
                                        selectedCenter.center_name
                                            ? selectedCenter.center_name
                                            : "Select Center"
                                    }
                                    id="select-center-dropdown"
                                    style={{width: "auto"}}
                                >
                                    {user.type !== "teacher" &&
                                        user.center_list.map((center, index) => (
                                            <NavDropdown.Item
                                                key={index}
                                                onClick={() =>
                                                    handleCenterChange(center._id, center.name)
                                                }
                                            >
                                                {center.name}
                                            </NavDropdown.Item>
                                        ))}
                                </NavDropdown>
                                <Nav.Item className={"hid"}>|</Nav.Item>
                                <NavDropdown
                                    title={user.user_data.name}
                                    id="user-dropdown"
                                    style={{width: "auto"}}
                                >
                                    <div className="nev-pro">
                                        <div style={{color: "white"}}>Username</div>
                                        <div
                                            style={{borderBottom: "1px solid white", padding: "10px"}}
                                        >
                                            {user.user_data.email}
                                        </div>
                                        <div
                                            style={{borderBottom: "1px solid white", padding: "10px"}}
                                        >
                                            <div style={{color: "white"}}>Role</div>
                                            <div>{user.type === "admin" ? "Admin" : "Teacher"}</div>
                                        </div>
                                    </div>
                                    <NavDropdown.Item
                                        onClick={() => handleLogout("logout")}
                                        style={{textAlign: "center"}}
                                    >
                                        Logout
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            ) : null}
        </>
    );
}
