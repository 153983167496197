import React, {useEffect, useState} from "react";
import moment from "moment";
import Header from "../Login/Header";
import Loader from "../../Components/Loader"
import "../../App.css"
import CreateBlogModal from "./CreateBlogModal"
import axiosInstance from "../../utils/api";
import {useAuth} from "../../auth/Auth";
import {Info} from "@mui/icons-material";
import ConfirmationModal from "../InputModals/ConfirmationModal";

const Blogs = () => {
    const {errorModal, successModal} = useAuth();
    const init = {
        open: false,
        title: null,
        id: null,
        short_description: null,
        is_active: false,
        time_to_read: null,
        topic: null
    }
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [editBlogs, setEditBlogs] = useState(init);
    const [areYou, setAreYou] = useState({open: false, data: {}, action: null, message: null});
    const topicOptions = ["All", "Food", "Health", "Habits", "Technology"];

    const [selectedTopic, setSelectedTopic] = useState("All");


    const OpenAreYou = (data, action, message) => {
        setAreYou({open: true, data: data, action: action, message: message})
    }

    const CloseAreYou = () => {
        setAreYou({open: false, data: {}, action: null, message: null})
    }


    const openEditModal = (editData) => {
        setEditBlogs({
            open: true,
            title: editData.title,
            id: editData._id,
            short_description: editData.short_description,
            is_active: editData.is_active,
            time_to_read: editData.time_to_read,
            topic: editData.topic
        })
    };

    const fetchBlogs = async (topic) => {
        setIsLoading2(true)
        setSelectedTopic(topic);
        try {
            let response
            if (topic === 'All') {
                response = await axiosInstance.get(
                    "/blog/posts?all"
                );
            } else {
                response = await axiosInstance.get(
                    `/blog/getPostByTopics?topic=${topic}`
                );
            }
            setIsLoading2(false)
            const data = response.data;
            setBlogs(data.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading2(false)
            errorModal()
            setIsLoading(false);
        }
    };


    const openCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const closeCreateModal = () => {
        setIsCreateModalOpen(false);
        setEditBlogs((prevState) => ({prevState, init}))
    };

    const deleteBlog = async (actionData) => {
        try {
            const response = await axiosInstance.delete(`/blog/delete?id=${actionData.data.id}`)
            let responseData = response.data
            if (responseData.status === 'success') {
                updateOnDelete(actionData.data.id)
                successModal('Deleted', 'Blog had been deleted successfully!')
            } else {
                errorModal()
            }
        } catch (error) {
            console.log("Error", error)
            errorModal()
        }
    }

    const updateOnDelete = (id) => {
        setBlogs((prevState) => prevState.filter(item => item._id !== id));
    };

    const updateBlog = (type, data) => {
        if (type === 'edit') {
            const updatedBlogs = blogs.map(blog => {
                if (blog._id === data._id) {
                    return {...blog, ...data};
                }
                return blog;
            });
            setBlogs(updatedBlogs);
        }

        if (type === 'create') {
            setBlogs([...blogs, data]);
        }
    };


    useEffect(() => {
        fetchBlogs('All');
    }, []);

    return (
        <>
            <Header/>
            <div className="blogs-container">
                {isLoading || isLoading2 ? (
                    <Loader/>
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-4" style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid lightgray",
                            paddingBottom: "30px"
                        }}>
                            <h1 className="text-2xl font-bold">ALL BLOGS</h1>
                            <button className="btn btn-sm btn-success"
                                    onClick={openCreateModal}>Create Blog
                            </button>
                        </div>
                        <div style={{marginBottom: "40px"}}>
                            <label htmlFor="topicDropdown">Select a Topic:</label>
                            <select
                                id="topicDropdown"
                                className="form-control"
                                value={selectedTopic}
                                onChange={(event) => fetchBlogs(event.target.value)}
                            >
                                {topicOptions.map((topic, index) => (
                                    <option key={index} value={topic}>
                                        {topic}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {blogs.length > 0 ? (
                            <div className="blogs-list">
                                {blogs && blogs.map((blog) => (
                                    <div className="blog-card" key={blog._id}
                                         style={{cursor: "pointer", zIndex: "999"}}>
                                        <div style={{
                                            backgroundColor: "white",
                                            borderRadius: "15px",
                                            width: "fit-content",
                                            padding: '0 10px',
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            fontSize: "14px"

                                        }}>{blog.topic}</div>
                                        <div onClick={() => openEditModal(blog)}>
                                            {blog?.short_description ? (
                                                <p className="blog-short-description"> {blog.short_description.length > 100
                                                    ? `${blog.short_description.substring(0, 100)}...`
                                                    : blog.short_description}</p>
                                            ) : null}
                                            <p className="blog-body">{blog.body}</p>
                                            <p className="blog-details">
                                                Uploaded by: {blog.uploaded_by} | Likes: {blog.like_count} | Time to
                                                Read: {blog.time_to_read}
                                            </p>
                                            <p className="blog-date">Created
                                                at: {moment(blog.createdAt).format("MMMM D, YYYY")}</p>
                                        </div>
                                        <div>
                                            <button
                                                className="btn btn-sm btn-danger"
                                                style={{zIndex: "99999999"}}
                                                // onClick={() => OpenAreYou(blog)}
                                                onClick={() => OpenAreYou({"id": blog._id}, deleteBlog, `Are your sure you want to delete the this blog?`)}

                                            >
                                                Delete
                                            </button>
                                            <button
                                                className="btn btn-sm btn-success"
                                                style={{marginLeft: "15px"}}
                                                onClick={() => openEditModal(blog)}>Edit
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="box_to_show">
                                <Info/>
                                No blogs available!
                            </div>
                        )}
                        {isCreateModalOpen || editBlogs.open ? (
                            <CreateBlogModal isOpen={true} closeModal={closeCreateModal}
                                             editData={editBlogs.open ? editBlogs : null}
                                             updateBlog={updateBlog}

                            />
                        ) : null}
                    </>
                )}
            </div>
            {areYou.open ? (
                <ConfirmationModal CloseAreYou={CloseAreYou} action={areYou.action} message={areYou.message}
                                   actionData={areYou}/>
            ) : null}

        </>
    );
};

export default Blogs;
