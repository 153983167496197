import {Oval} from "react-loader-spinner";
import React from "react";

const SmallAdjustableLoader = () => {
    return (
        <div style={{justifyContent: "center", display: "flex", margin: "20px 0"}}>
            <Oval
                height={30}
                width={30}
                color="lightgray"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />
        </div>
    )
}

export default SmallAdjustableLoader