import moment from "moment";
import React, {useState} from "react";
import SmallAdjustableLoader from "../../Components/SmallAdjustableLoader";
import ChildModal from "../InputModals/UniverselModal";
import Table from "react-bootstrap/Table";
import {Info} from "@mui/icons-material";


const ProgramChangeRequest = ({data, approveProgram, isLoadingTable, OpenAreYou}) => {
    const [childModal, setChildModal] = useState({open: false, data: null});
    const handleCloseModal = () => {
        setChildModal(
            {open: false, data: null}
        )
    }
    const handleChildModal = (id) => {
        setChildModal(
            {open: true, data: id}
        )
    }
    return (
        <>
            {isLoadingTable ? (
                <SmallAdjustableLoader/>
            ) : (
                <div className='table_box'>
                    {data.length > 0 ? (
                        <Table striped bordered hover style={{minWidth: "100%"}}>
                            {childModal.open ? (
                                <ChildModal
                                    id={childModal}
                                    handleCloseModal={handleCloseModal}
                                />
                            ) : null}
                            <thead
                                className="border-y-2 h-[40px] text-center bg-gray-500 text-white">
                            <tr>
                                <th>Name</th>
                                <th>Date of Birth</th>
                                <th>Gender</th>
                                <th>Admission Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td className="name_css"
                                        onClick={() => handleChildModal(item.child._id)}>{item.child.name}</td>
                                    <td>
                                        {moment.unix(item.child.dob).format('YYYY-MM-DD')}
                                    </td>
                                    <td>{item.child.gender}</td>
                                    <td>{moment.unix(item.child.admission_date).format('YYYY-MM-DD')}</td>
                                    <td className="capitalize"
                                        style={{gap: "20px", display: "flex", justifyContent: "center"}}>
                                        <button
                                            disabled={item.is_approved === 0 ? false : item.is_approved === 1}
                                            className={"btn btn-success btn-sm"}
                                            onClick={() => OpenAreYou({
                                                    "item_id": item._id,
                                                    "child_id": item.child_id,
                                                    "new_program_id": item._id,
                                                    "type": 'accepted'
                                                }, approveProgram,
                                                'Are You Sure you want to approve this request?')}
                                        >
                                            {'Approve'}
                                        </button>
                                        <button
                                            disabled={item.is_approved === 0 ? false : item.is_approved === -1}
                                            className={"btn btn-danger btn-sm"}
                                            onClick={() => OpenAreYou({
                                                    "item_id": item._id,
                                                    "child_id": item.child_id,
                                                    "new_program_id": item._id,
                                                    "type": 'rejected'
                                                }, approveProgram,
                                                'Are You Sure you want to reject this request?')}
                                        >
                                            {'Reject'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="box_to_show">
                            <Info/>
                            No data available!
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default ProgramChangeRequest