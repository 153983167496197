import React, {lazy, Suspense, useRef} from "react";
import {Routes, Route} from "react-router-dom";
import {useAuth} from "../auth/Auth";
import Loader from "../Components/Loader";
import Blogs from "../Pages/Admin/Blogs";
import CustomerRequest from "../Pages/CustomerRequest/CustomerRequest";
import PrivacyPolicy from "../Pages/Dashboard/PrivacyPolicy";
import LiveStreaming from "../Pages/Inbox/LiveStreaming";
import PoliciesScreen from '../Pages/Dashboard/Terms&Condition';
import RefundPolicy from '../Pages/Dashboard/RefundPolicy';

const Protected = lazy(() => import("../auth/Protected"));
const Login = lazy(() => import("../Pages/Login/Login"));
const DashboardDailyUpdates = lazy(
    () => import("../Pages/Dashboard/DashboardDailyUpdates"),
);
const AddNewEmpForm = lazy(() => import("../Pages/Employee/AddNewEmpForm"));
const AdmissionDB = lazy(() => import("../Pages/Dashboard/AdmissionDB"));
const AdmissionNumbers = lazy(
    () => import("../Pages/Childrens/AdmissionNumbers"),
);
const AdultChildRatio = lazy(() => import("../Pages/Employee/AdultChildRatio"));
const AllChildrens = lazy(() => import("../Pages/Childrens/AllChildrens"));
const AttritionChildrens = lazy(
    () => import("../Pages/Childrens/AttritionChildrens"),
);
const Attendance = lazy(() => import("../Pages/SupermamAPP/Attendance"));
const CareCallsArchive = lazy(
    () => import("../Pages/Childrens/CareCallsArchive"),
);
const ChildAttendanceReport = lazy(
    () => import("../Pages/Childrens/ChildAttendanceReport"),
);
const CollectionReport = lazy(
    () => import("../Pages/Childrens/CollectionReport"),
);
const DayCareRegistration = lazy(
    () => import("../Pages/Childrens/DayCareRegistration"),
);
const DemoAcc = lazy(() => import("../Pages/Inbox/DemoAcc"));
const EmergencyDaycare = lazy(
    () => import("../Pages/Childrens/EmergencyDaycare"),
);
const Employee = lazy(() => import("../Pages/Employee/Employee"));
const EmployeeAttendance = lazy(
    () => import("../Pages/Employee/EmployeeAttendance"),
);
const Events = lazy(() => import("../Pages/Inbox/Events"));
const ExEmployees = lazy(() => import("../Pages/Employee/ExEmployees"));
const FeeCalculator = lazy(() => import("../Pages/Childrens/FeeCalculator"));
const FeeCard = lazy(() => import("../Pages/Childrens/FeeCard"));
const FoodMenu = lazy(() => import("../Pages/Inbox/FoodMenu"));
const Invoices = lazy(() => import("../Pages/Childrens/Invoices"));
const Measurement = lazy(() => import("../Pages/SupermamAPP/Measurement"));
const MedicalRecords = lazy(
    () => import("../Pages/SupermamAPP/MedicalRecords"),
);
const NPSSummary = lazy(() => import("../Pages/Childrens/NPSSummary"));
const OneTimeCharges = lazy(() => import("../Pages/Childrens/OneTimeCharges"));
const ParentOrientation = lazy(
    () => import("../Pages/Childrens/ParentOrientation"),
);
const PlannerList = lazy(() => import("../Pages/Inbox/PlannerList"));
const RecentUpdateDetails = lazy(
    () => import("../Pages/Childrens/RecentUpdateDetails"),
);
const RevenueReport = lazy(() => import("../Pages/Childrens/RevenueReport"));
const SalesDashboard = lazy(() => import("../Pages/Dashboard/SalesDashboard"));
const SharedDocs = lazy(() => import("../Pages/Inbox/SharedDocs"));
const Supermam = lazy(() => import("../Pages/Employee/Supermam"));
const VisitSchedule = lazy(() => import("../Pages/Childrens/VisitSchedule"));
const AdmissionForm = lazy(() => import("../Pages/Admin/AdmissionForm"));
const OnlineAdmissionForm = lazy(
    () => import("../Pages/Admin/OnlineAdmissionForm"),
);
const CreateCenter = lazy(() => import("../Pages/Admin/AvailableCenter"));
const CreateClassroom = lazy(() => import("../Pages/Admin/ClassRooms"));
const UpdateClassroom = lazy(() => import("../Pages/Admin/UpdateClassroom"));
const UpdateCenter = lazy(() => import("../Pages/Admin/UpdateCenter"));
const CreateProgram = lazy(() => import("../Pages/Admin/Programs"));
const Conversation = lazy(() => import("../Pages/SupermamAPP/Conversation"));
const AddAdminForm = lazy(() => import("../Pages/Admin/AddAdminForm"));
const Admin = lazy(() => import("../Pages/Admin/Admin"));
const OnlineAdmissionForm2 = lazy(
    () => import("../Pages/Admin/OnlineAdmissionForm2"),
);
const ChildAdmission = lazy(() => import("../Pages/Admin/ChildAdmission"));
const Announcements = lazy(() => import("../Pages/Childrens/Announcements"));
const NotFound = lazy(() => import("../Pages/Login/PageNotFound"));
const Holidays = lazy(() => import("../Pages/Admin/Holidays"));
const FAQ = lazy(() => import("../Pages/FAQ/FAQ"));
const FeeStructure = lazy(() => import("../Pages/Childrens/FeeStructure"));

const AppRoutes = () => {
    const {user} = useAuth();
    const ref = useRef(null)


    return (
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/live_streaming" element={<LiveStreaming/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/terms-&-conditions" element={<PoliciesScreen/>}/>
                <Route path="/refund-policy" element={<RefundPolicy/>}/>
                <Route path="/fee_card" element={<FeeCard/>}/>
                <Route path="/redirect_to_payment" element={<OnlineAdmissionForm2/>}/>
                <Route
                    path="/onlineAdmissionForm"
                    element={<OnlineAdmissionForm/>}
                />
                <Route
                    path="/redirect_to_payment/:id"
                    element={<OnlineAdmissionForm2/>}
                />
                <Route path="*" element={<NotFound/>}/>
                <Route path="/not_found" element={<NotFound/>}/>
                <Route
                    path=""
                    element={
                        <Protected user={user}>
                            <Login/>
                        </Protected>
                    }
                >
                    <Route path="/daily_update" element={<DashboardDailyUpdates/>}/>
                    <Route path="" element={<DashboardDailyUpdates/>}/>
                    <Route path="/addEmployee" element={<AddNewEmpForm/>}/>
                    <Route path="/admission_dashboard" element={<AdmissionDB/>}/>
                    <Route path="/admission_numbers" element={<AdmissionNumbers/>}/>
                    <Route path="/adult_child_ratio" element={<AdultChildRatio/>}/>
                    <Route path="/all_children" element={<AllChildrens/>}/>
                    <Route path="/attendance" element={<Attendance/>}/>
                    <Route path="/attritionChildrens" element={<AttritionChildrens/>}/>
                    <Route path="/care_calls_archive" element={<CareCallsArchive/>}/>
                    <Route
                        path="/child_attendance_report"
                        element={<ChildAttendanceReport/>}
                    />
                    <Route path="/collectionReport" element={<CollectionReport/>}/>
                    <Route
                        path="/customer_request"
                        element={
                            <CustomerRequest/>
                        }
                    />
                    <Route
                        path="/dayCareRegistration"
                        element={<DayCareRegistration/>}
                    />
                    <Route path="/demoAcc" element={<DemoAcc/>}/>
                    <Route path="/emergencyDaycare" element={<EmergencyDaycare/>}/>
                    <Route path="/employee" element={<Employee/>}/>
                    <Route path="/employeeAttendance" element={<EmployeeAttendance/>}/>
                    <Route path="/events" element={<Events/>}/>
                    <Route path="/announcements" element={<Announcements/>}/>
                    <Route path="/exEmployees" element={<ExEmployees/>}/>
                    <Route path="/exEmployees" element={<ExEmployees/>}/>
                    <Route path="/fee_calculator" element={<FeeCalculator/>}/>
                    <Route path="/fee_Structure" element={<FeeStructure/>}/>
                    <Route path="/food_menu" element={<FoodMenu/>}/>
                    <Route path="/feeCard" element={<FeeCard/>}/>
                    <Route path="/invoices" element={<Invoices/>}/>
                    <Route path="/measurement" element={<Measurement/>}/>
                    <Route path="/medical_records" element={<MedicalRecords/>}/>
                    <Route path="/npsSummary" element={<NPSSummary/>}/>
                    <Route path="/oneTimeCharges" element={<OneTimeCharges/>}/>
                    <Route path="/parentOrientation" element={<ParentOrientation/>}/>
                    <Route path="/plannerList" element={<PlannerList/>}/>
                    <Route
                        path="/recentUpdateDetails"
                        element={<RecentUpdateDetails/>}
                    />
                    <Route path="/revenueReport" element={<RevenueReport/>}/>
                    <Route path="/salesDashboard" element={<SalesDashboard/>}/>
                    <Route path="/sharedDocs" element={<SharedDocs/>}/>
                    <Route path="/supermam" element={<Supermam/>}/>
                    <Route path="/visitSchedules" element={<VisitSchedule/>}/>
                    <Route path="/admissionForm" element={<AdmissionForm/>}/>
                    <Route path="/centers" element={<CreateCenter/>}/>
                    <Route path="/classrooms" element={<CreateClassroom/>}/>
                    <Route path="/updateClassroom" element={<UpdateClassroom/>}/>
                    <Route path="/updateCenter" element={<UpdateCenter/>}/>
                    <Route path="/programs" element={<CreateProgram/>}/>
                    <Route path="/conversation" element={<Conversation/>}/>
                    <Route path="/admin" element={<Admin/>}/>
                    <Route path="/addAdminForm" element={<AddAdminForm/>}/>
                    <Route path="/childAdmission" element={<ChildAdmission/>}/>
                    <Route path="/holidays" element={<Holidays/>}/>
                    <Route path="/blog" element={<Blogs/>}/>
                    <Route path="/faq" element={<FAQ/>}/>
                </Route>
            </Routes>
        </Suspense>
    );
};

export default React.memo(AppRoutes);
