import React, {useEffect, useState} from "react";
import Header from "../Login/Header";
import TakeBreakTable from "./TakeBreakTable";
import axiosInstance from "../../utils/api";
import ProgramChangeRequest from "./ProgramChangeRequest";
import CenterChangeRequest from "./CenterChangeRequest";
import {Info} from "@mui/icons-material";
import Loader from "../../Components/Loader"
import {useAuth} from "../../auth/Auth";
import ConfirmationModal from "../InputModals/ConfirmationModal";

const CustomerRequest = () => {
    const {user, successModal, errorModal, selectedCenter} = useAuth();
    const [tableData, setTableData] = useState([]);
    const [tableDataPro, setTableDataPro] = useState([]);
    const [tableDataCenter, setTableDataCenter] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedType, setSelectedType] = useState('all');
    const [center, setSelectedCenter] = useState(selectedCenter.center_id);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [areYou, setAreYou] = useState({open: false, data: {}, action: null, message: null});

    const OpenAreYou = (data, action, message) => {
        setAreYou({open: true, data: data, action: action, message: message})
    }

    const CloseAreYou = () => {
        setAreYou({open: false, data: {}, action: null, message: null})
    }


    const approveBreak = async (actionData) => {
        try {
            setIsLoadingTable(true)
            const response = await axiosInstance.get(`/takebreak/${actionData.data.type}?id=${actionData.data.id}`)
            let responseData = response.data
            setTableData((prevData) =>
                prevData.map((item) =>
                    item._id === actionData.data.id ? {
                        ...item,
                        is_approved: actionData.data.type === "accept" ? 1 : -1
                    } : item,
                ),
            );
            if (responseData.status === 'success') {
                setIsLoadingTable(false)
                successModal('Approved', 'Brake has been Approved!')
            } else {
                setIsLoadingTable(false)
                errorModal()
            }
        } catch (error) {
            console.log("error error", error?.response?.data?.data)
            setIsLoadingTable(false)
            if (error?.response?.data?.data) {
                errorModal("Error", error?.response?.data?.data)
            } else {
                errorModal()
            }
        }
    }

    const approveProgram = async (actionData) => {
        console.log("actionData", actionData)
        try {
            setIsLoadingTable(true)
            let data = {
                child_id: actionData.data.child_id,
                type: actionData.data.type,
                changeprogram_id: actionData.data.new_program_id
            }
            const response = await axiosInstance.post(`/child/approveChangeProgram`, data)
            let responseData = response.data
            console.log("responseData", response)
            setTableDataPro((prevData) =>
                prevData.map((item) =>
                    item._id === actionData.data.item_id ? {
                        ...item,
                        is_approved: actionData.data.type === 'accepted' ? 1 : -1
                    } : item,
                ),
            );
            if (responseData.status === 'success') {
                setIsLoadingTable(false)
                successModal(actionData.data.type === 'accepted' ? 'Approved!' : 'Rejected', 'Brake has been Approved!', actionData.data.type === 'accepted' ? 'Brake has been Approved!' : 'Brake has been Rejected!')
            } else {
                setIsLoadingTable(false)
                errorModal()
            }
        } catch (error) {
            console.log("error", error)
            setIsLoadingTable(false)
            errorModal()
        }
    }

    const ApporveCenterReq = async (actionData) => {
        console.log("actionData", actionData)
        try {
            setIsLoadingTable(true)
            let data = {
                child_id: actionData.data.child_id,
                type: actionData.data.type,
                changecenter_id: actionData.data.item_id
            }
            const response = await axiosInstance.post(`/child/approveChangeCenter`, data)
            let responseData = response.data
            setTableDataCenter((prevData) =>
                prevData.map((item) =>
                    item._id === actionData.data.item_id ? {
                        ...item,
                        is_approved: actionData.data.type === 'accepted' ? 1 : -1
                    } : item,
                ),
            );
            if (responseData.status === 'success') {
                setIsLoadingTable(false)
                successModal(actionData.data.type === 'accepted' ? 'Approved!' : 'Rejected', 'Request has been Approved!', actionData.data.type === 'accepted' ? 'Brake has been Approved!' : 'Brake has been Rejected!')
            } else {
                setIsLoadingTable(false)
                errorModal()
            }
        } catch (error) {
            console.log("error", error)
            setIsLoadingTable(false)
            errorModal()
        }
    }

    const TakeBreakRequestData = (centerId, type) => {
        setLoading(true)
        axiosInstance
            .get(`/takebreak/allChildsByCenterId?break_type=${type}&center_id=${centerId}`)
            .then((response) => {
                setTableData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false)
                console.error("Error fetching data:", error);
                errorModal()
            });
    }

    const CenterRequestData = async (centerId, type) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`/child/allChangeCenterList?center_id=${centerId}&type=${type}`)
            let responseData = response.data
            if (responseData.status === "success") {
                setTableDataCenter(responseData.data)
                setLoading(false)
            } else {
                setLoading(false)
                errorModal()
            }
        } catch (error) {
            setLoading(false)

            console.log("error", error)
        }
    }

    const selectedCenterHandler = (id) => {
        setSelectedCenter(id)
        setSelectedOption("")
        setTableDataPro([])
        setTableDataCenter([])
        setTableData([])
    }

    const ProgramChangeRequestData = async (centerId, type) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`/child/allChangeProgramList?center_id=${centerId}&type=${type}`)
            let responseData = response.data
            if (responseData.status === "success") {
                setTableDataPro(responseData.data)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log("I am error")
        }
    }

    const handleRequestSelection = (centerId, type, requestType) => {
        console.log("centerIdcenterId", centerId)
        setSelectedCenter(centerId)
        setSelectedType(type)
        setSelectedOption(requestType)
        if (requestType) {
            if (requestType === "program") {
                ProgramChangeRequestData(centerId, type)

            } else if (requestType === "center") {
                CenterRequestData(centerId, type)

            } else if (requestType === "break") {
                TakeBreakRequestData(centerId, type)
            }
        }

    }

    // useEffect(() => {
    //     if (selectedOption) {
    //         if (selectedOption === "program") {
    //             ProgramChangeRequestData(center)
    //
    //         } else if (selectedOption === "center") {
    //             CenterRequestData(center)
    //
    //         } else if (selectedOption === "break") {
    //             TakeBreakRequestData(center)
    //         }
    //     }
    //
    // }, [selectedOption]);

    // const handleChange = (value) => {
    //     setSelectedOption(value)
    // };

    return (
        <>
            <Header/>
            {loading ? (
                <Loader/>
            ) : (
                <div className='custom-pad'>
                    <div className="flex justify-between items-center mb-4  border-bottom py-2">
                        <h1 className="heading_color">Customer Request</h1>
                    </div>
                    <div className="row  mb-4 mt-4"
                         style={{borderBottom: "1px solid lightgray", paddingBottom: "30px"}}>
                        <>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                gap: "20px"
                            }}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <div className="col-md-6" style={{marginRight: "20px"}}>
                                        <small className="font-bold-gray">Select a service:</small>
                                        <select
                                            disabled={center ? false : true}
                                            className="selectpicker form-control"
                                            data-style="btn-primary"
                                            value={selectedOption}
                                            onChange={(e) => handleRequestSelection(center, selectedType, e.target.value)}>
                                            <option value="">Select center</option>
                                            <option value="break">Take Break Request</option>
                                            <option value="program">Program Change Request</option>
                                            <option value="center">Center Change Request</option>
                                        </select>
                                    </div>
                                    <div className="mr-4">
                                        <small className="font-bold-gray">Center:</small>

                                        <select
                                            disabled={user.type === 'teacher'}
                                            className="selectpicker form-control"
                                            data-style="btn-primary"
                                            value={center}
                                            onChange={(e) => handleRequestSelection(e.target.value, selectedType, selectedOption)}
                                        >
                                            <option value="">Select center</option>
                                            {user.center_list.map((item) => (
                                                <option value={item.id} key={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <small className="font-bold-gray">Select a type:</small>
                                    <select
                                        className="selectpicker form-control"
                                        data-style="btn-primary"
                                        value={selectedType}
                                        onChange={(e) => handleRequestSelection(center, e.target.value, selectedOption)}
                                    >
                                        <option value="all">ALL</option>
                                        <option value="pending">Pending</option>
                                        <option value="accepted">Accepted</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    </div>
                    <>
                        {center ? (
                            <>
                                {selectedOption === "break" ? (
                                    <TakeBreakTable tableData={tableData}
                                                    approveBreak={approveBreak}
                                                    isLoadingTable={isLoadingTable}
                                                    OpenAreYou={OpenAreYou}
                                    />
                                ) : selectedOption === "program" ? (
                                    <ProgramChangeRequest
                                        data={tableDataPro}
                                        approveProgram={approveProgram}
                                        isLoadingTable={isLoadingTable}
                                        OpenAreYou={OpenAreYou}
                                    />
                                ) : selectedOption === "center" ? (
                                    <CenterChangeRequest
                                        data={tableDataCenter}
                                        OpenAreYou={OpenAreYou}
                                        ApporveCenterReq={ApporveCenterReq}
                                        isLoadingTable={isLoadingTable}
                                    />

                                ) : (
                                    <div className="box_to_show">
                                        <Info/>
                                        Select a service to show requests
                                    </div>
                                )}

                            </>
                        ) : (
                            <div className="box_to_show">
                                <Info/>
                                Select a service to show requests
                            </div>
                        )}
                    </>


                </div>
            )}
            <>
            </>
            {areYou.open ? (
                <ConfirmationModal
                    CloseAreYou={CloseAreYou}
                    action={areYou.action}
                    message={areYou.message}
                    actionData={areYou}
                />
            ) : null}
        </>
    );
};

export default CustomerRequest;
