import {Oval, RotatingLines} from "react-loader-spinner";
import React from "react";

const Loader = () => {

    return (
        <div style={{
            height: "89vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>

            <RotatingLines
                visible={true}
                height="40"
                width="40"
                strokeColor={"rgb(155, 81, 224)"}
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}

export default React.memo(Loader)
