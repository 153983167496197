import React from 'react'
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from "./auth/Auth";
import AppRoutes from "./routes/AppRoutes";
import LoadingBar from "react-top-loading-bar";
// import "./App.css"

export default function App() {

    return (
        <BrowserRouter>
            <AuthProvider>
                <AppRoutes/>
            </AuthProvider>
        </BrowserRouter>
    )
}
