import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://app.mickeytots.com/api',
    // baseURL: 'http://167.71.229.156:3000',
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = JSON.parse(window.localStorage.getItem('userData'))?.access_token;
        if (token) {
            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            }
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.Accept = 'application/json';
            config.headers['Access-Control-Allow-Origin'] = '*';
            config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
