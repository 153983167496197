import React from "react";
import Header from '../Login/Header';

function PoliciesScreen() {
  return (
      <>
        <Header />
        <div className="policies-container">
          <div className="card">
            <h1 className="title">Illness Policy</h1>
            <p className="text">
              Sometimes it may be difficult to understand that the Pupil is ill and
              requires rest at home or a visit to the Doctor. We strongly advise
              that if the Pupil is suffering from any of the symptoms, the Pupil
              should not be sent to School or in the event the symptoms arise
              (including the following) while already at the School, the Pupil may
              be sent home:
            </p>
            <p className="text">(1) Fever – mild or high (of 100 degrees or higher)</p>
            <p className="text"> (2) Cold or Cough or Body Ache</p>
            <p className="text"> (3) Vomiting, diarrhea or severe nausea</p>
            <p className="text"> (4) Rashes or patches of broken, itchy skin;</p>
            <p className="text"> (5) Any communicable diseases/illness;</p>
            <p className="text">
              (6) Any unexplained illness which causes the Pupil discomfort of a
              level that the Pupil is unable to participate in the Program or
              activities. If a Pupil who is too ill to remain at the School shall be
              placed in separate care at the School and the Parents will be notified
              of the Pupil’s illness and will be required to pick up the Pupil
              within 45 minutes. For the safety of the Pupil it is imperative that
              you notify the School Founder if any medication has been administered
              to the Pupil within the last 24 hours. Should there be a medical
              emergency it is crucial to report whether or not the Pupil is on
              medication.
            </p>
            <h1 className="title">Nail And Lice Policy</h1>
            <p className="text">
              School Founder reserves the right to send a Pupil with grown nails or
              lice in hair back home. Parents will appreciate the measure that is to
              be taken to protect children from inadvertently hurting one another.
            </p>
            <h1 className="title">Pause Policy</h1>
            <p className="text">
              The School Founder has a unique policy of pause. Under this policy,
              Parents can pause the services received by Pupil, if they do not send
              the Pupil for 30 days or more. Pause requests must be made at least 1
              day in advance from the 1st day of absence. A Pause request must be a
              minimum for 30 days and a maximum of 90 days. During Pause period,
              Parents would only be charged 33% of the applicable Monthly Fee
              (payable each month) and remaining 67% of Monthly Fee will be adjusted
              from Pause Credits. All pause requests must be made through the App.
            </p>
            <h1 className="title">Late Pickup Charges</h1>
            <p className="text">
              Parents agree to pick the Pupil from the School, as per the School’s/
              Program’s timings and thereafter the School Founder shall not be
              liable and responsible towards such Parents/ Pupil. However, in case
              of delays due and only in cases of emergencies, Parents shall ensure
              that they inform the School Founder/ School at least 1 (one) hour
              prior to the School’s closing time to enable the School Founder to
              make arrangements to keep the Pupil until the Pupil is picked by the
              Parents.
            </p>
            <p className="text">
              In case a Pupil is picked up later than the Program timings, an
              additional charge will be applicable. Delay up to the first 15
              minutes, no charges are applied, but if the late Pickup exceeds beyond
              the first 15 minutes, then late Pick up charges will apply. For
              example, if the School closes at 6:30 pm, no late pickup charges will
              apply until 6:45 pm but if the Pupil is picked after that, at any
              time, until 7:30 pm, Rs. 100/- will be charged. For every additional 1
              hour late pickup, a charge of Rs. 100/- (Rupees One Hundred only) will
              be levied.
            </p>
            <p className="text">
              Similar charges are applicable in case Pupil is reaching earlier than
              the program timings (Early Arrival)
            </p>
            <h1 className="title">Mandatory Components</h1>
            <p className="text">
              Food and CCTV are mandatory components at this School so Parents will
              be billed for these services along with Monthly Fee irrespective of
              Parents actually using these services or not.
            </p>
            <h1 className="title">General Terms</h1>
            <p className="text">
              Parents hereby accord their consent to the School Founder and Mickey
              Tots to use CCTVs, still photography and videography at School and
              School organized events. Parents understand and acknowledge that such
              photographic & video images of the Pupil may be used internally or
              externally to document the growth and development of the Pupil, for
              School related functions & communication, School’s advertisement,
              photo albums, brochures, press releases, websites or any other offline
              or online activity by School and / or Mickey Tots. It is made known
              that such images and videos are taken in good faith while ensuring the
              dignity of the children. It is hereby clarified that no additional
              permissions shall be taken from the Parents before taking and/ or
              using such photographs and video images and the Parents hereby agrees
              not to raise any objection in this regard.
            </p>
            <p className="text">
              Parents understand and acknowledge that it is the policy of School
              that, in order to reduce risk of spreading any disease, a child
              (including the Pupil ) with any disease that can be contagious may not
              be allowed in the School. School Founder has the authority to take
              decision on such matters.
            </p>
            <p className="text">
              Parents hereby authorize, appoint and empower the School Founder and
              its staff to act as their agent to furnish on their behalf any oral or
              written authorization as may be required before taking any medical or
              surgical services. Parents hereby release the School Founder and
              Mickey Tots, for any liability which might arise from giving such
              authorization; it being their desire that the Pupil be furnished with
              such medical or surgical services as soon as reasonably possible after
              the need arises. Attempts will be made to inform the Parents
              beforehand, if the circumstances of the situation so permits.
            </p>
            <h1 className="title">CCTV Live Feed</h1>
            <p className="text">
              Parents understand that the Live CCTV feed provided to the Parents is
              delayed by few minutes and is strictly for personal use. Such CCTV
              feed is meant for Parents only and shall not be shared by Parents with
              any third party. Parents agree to take full responsibility of any
              incident happening due to any sharing of feed with anybody. Further,
              Parents also understand that the CCTV feed provided to them is only
              for 2 logins and for any extra logins there may be additional charges.
              Parents acknowledge that smooth operations of CCTV Feed transmission
              depends upon several factors including but not limited to internet
              connectivity available at the School and that the School Founder
              cannot be blamed for interruptions in CCTV Feed transmission for
              reasons which are beyond its control such as technical glitches,
              reasons attributable to Internet Service Providers, malwares, viruses,
              time taken in restoration of transmission, etc.
            </p>
            <p className="text">
              Parents shall disclose any health issues, symptoms, allergies, etc. of
              a Pupil to the School Founder. Moreover, Parents acknowledge that in
              spite of School Founder taking reasonable care at the School, it is
              likely that Pupil, Parents and other visitors at the School may get
              infected with certain contagious diseases, included but not limited to
              Corona infection. Parents understand that School Founder is taking
              reasonable care at the School and parents shall be cautious and
              understand the situation.
            </p>
          </div>
        </div>
      </>
  );
}

export default PoliciesScreen;
