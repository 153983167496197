import Header from "../Login/Header";
import React from "react";


const PrivacyPolicy = () => {
    return (
        <>
            <Header/>
            <>
                <div className="container">
                    <h1 className="text-center"
                        style={{ borderBottom: "1px solid lightgray", paddingBottom: "15px", marginBottom: "30px" }}>
                        PRIVACY POLICY
                    </h1>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Address
                        </h1>
                        <p>
                             52/45 ground floor,infront metro mass hospital,jaipur,Rajasthan,302020
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Privacy Policy for https://app.mickeytots.com/
                        </h1>
                        <p>
                            At https://app.mickeytots.com/, accessible from https://app.mickeytots.com/, one of our main
                            priorities is the privacy of our visitors. This Privacy Policy document contains types of
                            information that are collected and recorded by https://app.mickeytots.com/ and how we use
                            it.

                            If you have additional questions or require more information about our Privacy Policy, do
                            not hesitate to contact us through email at team@mickeytots.com
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Log Files
                        </h1>
                        <p>
                            https://app.mickeytots.com/ follows a standard procedure of using log files. These files log
                            visitors when they visit websites. All hosting companies do this and a part of hosting
                            services' analytics. The information collected by log files includes internet protocol (IP)
                            addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                            referring/exit pages, and possibly the number of clicks. These are not linked to any
                            information that is personally identifiable. The purpose of the information is for analyzing
                            trends, administering the site, tracking users' movement on the website, and gathering
                            demographic information.
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Cookies and Web Beacons
                        </h1>
                        <p>
                            Like any other website, https://app.mickeytots.com/ uses 'cookies'. These cookies are used
                            to
                            store information including visitors' preferences, and the pages on the website that the
                            visitor accessed or visited. The information is used to optimize the users' experience by
                            customizing our web page content based on visitors' browser type and/or other information.
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Privacy Policies

                        </h1>
                        <p>
                            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
                            Beacons that are used in their respective advertisements and links that appear on
                            https://app.mickeytots.com/, which are sent directly to users' browser. They automatically
                            receive your IP address when this occurs. These technologies are used to measure the
                            effectiveness of their advertising campaigns and/or to personalize the advertising content
                            that you see on websites that you visit.

                            Note that https://app.mickeytots.com/ has no access to or control over these cookies that
                            are
                            used by third-party advertisers.

                            All credit/debit cards details and personally identifiable information will NOT be stored,
                            sold, shared, rented or leased to any third parties.
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Third Party Privacy Policies
                        </h1>
                        <p>
                            https://app.mickeytots.com/'s Privacy Policy does not apply to other advertisers or
                            websites.
                            Thus, we are advising you to consult the respective Privacy Policies of these third-party ad
                            servers for more detailed information. It may include their practices and instructions about
                            how to opt-out of certain options. You may find a complete list of these Privacy Policies
                            and their links here: Privacy Policy Links.

                            You can choose to disable cookies through your individual browser options. To know more
                            detailed information about cookie management with specific web browsers, it can be found at
                            the browsers' respective websites. What Are Cookies?
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Children's Information
                        </h1>
                        <p>
                            Another part of our priority is adding protection for children while using the internet. We
                            encourage parents and guardians to observe, participate in, and/or monitor and guide their
                            online activity.

                            https://app.mickeytots.com/ does not knowingly collect any Personal Identifiable Information
                            from children under the age of 13. If you think that your child provided this kind of
                            information on our website, we strongly encourage you to contact us immediately and we will
                            do our best efforts to promptly remove such information from our records.
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Account Protection
                        </h1>
                        <p>
                            Your security is important to us. Hence, we promise that all critical security updates
                            regarding your account will be made available to you via instant E-mail or SMS updates.
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Online Privacy Policy Only
                        </h1>
                        <p>
                            This Privacy Policy applies only to our online activities and is valid for visitors to our
                            website with regards to the information that they shared and/or collect in
                            https://app.mickeytots.com/. This policy is not applicable to any information collected
                            offline
                            or via channels other than this website. At present, since we offer free data to the users
                            who sign up, we do not allow deletion of accounts. However, you can make your profile
                            private. Private profiles will not show up on MickeyTots User Search, Index page profiles,
                            and
                            on the MickeyTots.com sitemap. Hence, no one can access your profile without its link. Your
                            resume will only be visible to you and the MickeyTots staff. All other features remain
                            unaffected.

                            MickeyTots does NOT share your data with any external organization under any circumstances.
                            Exceptionally, on clicking the connect with education loan partners check box on the signup
                            form, we will be bound to share your MickeyTots profile details with the loan partners.
                        </p>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <h1 style={{ fontSize: "25px" }}>
                            Consent
                        </h1>
                        <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and
                            Conditions.</p>
                    </div>
                </div>
            </>
        </>
    )
}

export default PrivacyPolicy
