import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Modal from 'react-modal';
import axiosInstance from '../../utils/api';
import SmallAdjustableLoader from '../../Components/SmallAdjustableLoader';
import moment from "moment";
import {useAuth} from "../../auth/Auth";
import {host_port_image} from "../../env";
import ConfirmationModal from "./ConfirmationModal";

const ChildModal = ({id, handleCloseModal}) => {
    const {user, errorModal, successModal, selectedCenter} = useAuth();
    const [showModal, setShowModal] = useState(true);
    const [selectedCenterId, setSelectedCenterId] = useState(selectedCenter.center_id);
    const [classRoomData, setClassRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSend, setIsLoadingSend] = useState(false);
    const [profileImage, setProfileImage] = useState("");
    const [profileImageEd, setProfileImageEd] = useState("");
    const [chidId, setChidId] = useState(null);
    const [parentId, setParentId] = useState(null);
    const [childData, setChildData] = useState({});
    const [loadBtn, setLoadBtn] = useState({one: false, two: false});
    const [areYou, setAreYou] = useState({open: false, data: {}, action: null, message: null});

    const OpenAreYou = (data, action, message) => {
        setAreYou({open: true, data: data, action: action, message: message})
    }

    const CloseAreYou = () => {
        setAreYou({open: false, data: {}, action: null, message: null})
    }

    const fetchData = async () => {
        try {
            console.log("id", id)
            const response = await axiosInstance.get(`/child/childById?id=${id.data}`);
            const data = response.data.data[0];
            setChildData(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCenterChange = async (value) => {
        setSelectedCenterId(value)

        try {
            const responseClass = await axiosInstance.get(
                `/classroom/classroomsByCenterId?center_id=${value}`,
            );
            const classData = responseClass.data.data
            setClassRoomData(classData)
        } catch (error) {
            console.log("Error", error)
            errorModal()
        }
    }

    useEffect(() => {
        handleCenterChange(selectedCenterId)
        fetchData();
    }, []);

    const initialValues = {
        name: '',
        gender: '',
        admissionDate: '',
        joiningDate: '',
        teacher: '',
        classroom: '',
        fatherName: '',
        fatherEmail: '',
        fatherPhone: '',
        motherName: '',
        motherEmail: '',
        motherPhone: '',
        programId: '',
        programName: '',
        centerId: '',
        dob: '',
        isActive: '',
        allergies: '',
        parentId: '',
        teacherId: '',
        admissionId: '',
    }

    const initialValuesParent = {
        fatherName: '',
        fatherEmail: '',
        fatherPhone: '',
        motherName: '',
        motherEmail: '',
        motherPhone: '',
    }

    useEffect(() => {
        if (childData) {
            const initialValuess = {
                name: childData.name || '',
                gender: childData.gender || '',
                admissionDate: childData.admission_date ? moment.unix(childData.admission_date).format("YYYY-MM-DD") : '',
                joiningDate: childData.joining_date ? moment.unix(childData.joining_date).format("YYYY-MM-DD") : '',
                teacher: childData.teacher_id?.name || '',
                teacherId: childData.teacher_id?._id || '',
                classroom: childData.classroom_id?._id || '',
                programId: childData.program_id?._id || '',
                dob: moment.unix(childData.dob).format("YYYY-MM-DD") || '',
                programName: childData.program_id?.name || '',
                isActive: childData.is_active ? "Active" : "Inactive" || '',
                allergies: childData.allergies || 'NA',
                parentId: childData.parent_id?._id || '',
                admissionType: childData.admission_type || '',
                centerId: childData.center_id || '',
                admissionId: childData.admission_id || '',
                isAttritioned: childData.is_attritioned || '',
            }
            const initialValuessParent = {
                fatherName: childData.parent_id?.father_name || '',
                fatherEmail: childData.parent_id?.father_email || '',
                fatherPhone: childData.parent_id?.father_phone || '',
                motherName: childData.parent_id?.mother_name || '',
                motherEmail: childData.parent_id?.mother_email || '',
                motherPhone: childData.parent_id?.mother_phone || '',
            }
            formik.setValues(initialValuess)
            formikParent.setValues(initialValuessParent)
            setProfileImageEd(childData.image_id)
            setChidId(childData._id)
            setParentId(childData.parent_id?._id)
        }
    }, [childData])


    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        gender: Yup.string().required('Gender is required'),
        admissionDate: Yup.date().required('Admission Date is required'),
        joiningDate: Yup.date().required('Joining Date is required'),
        teacherId: Yup.string().required('Teacher is required'),
        classroom: Yup.string().required('Classroom is required'),
        parentId: Yup.string().required('Mother Phone is required'),
        centerId: Yup.string().required('Mother Phone is required'),
    });

    const validationSchema2 = Yup.object().shape({
        fatherName: Yup.string().required('Father Name is required'),
        fatherEmail: Yup.string().email('Invalid email').required('Father Email is required'),
        fatherPhone: Yup.string().required('Father Phone is required'),
        motherName: Yup.string().required('Mother Name is required'),
        motherEmail: Yup.string().email('Invalid email').required('Mother Email is required'),
        motherPhone: Yup.string().required('Mother Phone is required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setLoadBtn({one: true, two: false})
            let ch_dob = moment(values.dob).unix();
            let ch_doj = moment(values.joiningDate).unix();
            let admission_date = moment(values.admission_date).unix();
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('gender', values.gender);
            formData.append('dob', ch_dob);
            formData.append('joining_date', ch_doj);
            formData.append('admission_date', admission_date);
            formData.append('admission_type', values.admissionType);
            formData.append('parent_id', values.parentId);
            formData.append('program_id', values.programId);
            formData.append('center_id', values.centerId);
            formData.append('teacher_id', values.teacherId);
            formData.append('classroom_id', values.classroom);
            formData.append('admission_id', values.admissionId);
            formData.append('is_attritioned', values.isAttritioned);
            formData.append('allergies', values.allergies);
            formData.append('file', profileImage ? profileImage : profileImageEd);
            try {
                const response = await axiosInstance.put(
                    `/child/update?id=${chidId}`,
                    formData, {
                        headers: {
                            'X-Request-Content-Type': 'multipart/form-data',
                        },
                    },
                );
                let responseData = response.data
                if (responseData.status === 'success') {
                    setLoadBtn({one: false, two: false})
                    successModal('Updated!', 'Details has been upldated!')
                } else {
                    setLoadBtn({one: false, two: false})
                    errorModal()
                }
            } catch (error) {
                setLoadBtn({one: false, two: false})
                console.log("Error", error)
                errorModal()
            }

        },
    });


    const formikParent = useFormik({
        initialValuesParent,
        validationSchema2,
        onSubmit: async (values) => {
            setLoadBtn({one: false, two: true})
            const formData = new FormData();
            formData.append('father_email', values.fatherEmail);
            formData.append('father_phone', values.fatherPhone);
            formData.append('mother_email', values.motherEmail);
            formData.append('mother_phone', values.motherPhone);
            formData.append('father_name', values.fatherName);
            formData.append('mother_name', values.motherName);


            try {
                const response = await axiosInstance.put(
                    // `/parent/updateParentDetails?id=${parentId}`,
                    `/parent/update?id=${parentId}`,
                    formData, {
                        headers: {
                            'X-Request-Content-Type': 'multipart/form-data',
                        },
                    },
                );
                let responseData = response.data
                if (responseData.status === 'success') {
                    setLoadBtn({one: false, two: false})
                    successModal('Updated!', 'Details has been updated!')
                } else {
                    setLoadBtn({one: false, two: false})
                    errorModal()
                }
            } catch (error) {
                setLoadBtn({one: false, two: false})
                console.log("Error", error)
                errorModal()
            }

        },
    });

    const handleProfileImageUpload = (data) => {
        setProfileImage(data);
    };

    const sendAccDetails = async (actionData) => {
        setIsLoadingSend(true)
        CloseAreYou()
        try {
            let data = {
                "parent_id": actionData.data.item
            }
            const response = await axiosInstance.post(`/parent/sendAccountDetailsToParent`, data)
            let responseData = response.data
            if (responseData.status === 'success') {
                setIsLoadingSend(false)
                successModal("Sent!", "Credential Sheared!")
            } else {
                setIsLoadingSend(false)
                errorModal()
            }
        } catch (error) {
            setIsLoadingSend(false)
            console.log("Error", error)
            errorModal()
        }
    }


    return (
        <Modal isOpen={showModal} onRequestClose={() => handleCloseModal()} className='modal_react'>
            <>
                <>
                    <div className='row' style={{borderBottom: "1px solid lightgray", paddingBottom: "15px"}}>
                        <h1 className="heading_color" style={{color: "gray"}}>Children Details</h1>
                    </div>
                    <button onClick={() => handleCloseModal()}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                backgroundColor: "gray",
                                color: "white"
                            }}
                            className={"btn"}
                    >
                        Close
                    </button>
                </>
                <>
                    {isLoading ? (
                        <SmallAdjustableLoader/>
                    ) : (
                        <div style={{maxHeight: "80vh", overflowY: "auto", overflowX: "hidden"}}>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='row justify-content-between mt-3 mb-3'>
                                    <div className='col-sm-2'
                                         style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <label
                                            htmlFor="fileInput"

                                        >
                                            <img
                                                src={
                                                    profileImage ?
                                                        // ? URL.createObjectURL(host_port_image + profileImage)
                                                        URL.createObjectURL(profileImage) : profileImageEd ? host_port_image + profileImageEd
                                                            : "https://app.mickeytots.com/user.svg"
                                                }
                                                alt="Preview Box"
                                                className="rounded-circle"
                                                style={{width: "82px", height: "82px"}}
                                            />


                                            <input
                                                type="file"
                                                style={{display: "none"}}
                                                id="fileInput"
                                                name="profileImage"
                                                accept="image/gif, image/png, image/jpeg"

                                                onChange={(e) => handleProfileImageUpload(e.target.files[0])}
                                            />
                                        </label>

                                    </div>
                                    <div className='col-sm-3'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Name</small>
                                        <input type="text" name="name" onChange={formik.handleChange}
                                               value={formik.values.name} className="form-control"
                                               defaultValue={formik.initialValues.name}
                                               disabled
                                        />
                                        {formik.errors.name && <div>{formik.errors.name}</div>}
                                    </div>
                                    <div className='col-sm-3'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Gender</small>
                                        <select name="gender" onChange={formik.handleChange}
                                                value={formik.values.gender}
                                                className="form-control">
                                            <option value="" disabled>
                                                Select Gender
                                            </option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        {formik.errors.gender && <div>{formik.errors.gender}</div>}
                                    </div>
                                    <div className='col-sm-3'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Admission Date:</small>
                                        <input
                                            type="date"
                                            name="admissionDate"
                                            onChange={formik.handleChange}
                                            value={formik.values.admissionDate}
                                            className="form-control"
                                            disabled
                                        />
                                        {formik.errors.admissionDate && <div>{formik.errors.admissionDate}</div>}
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3 mb-3'>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Program</small>
                                        <input type="text" name="programName" onChange={formik.handleChange}
                                               value={formik.values.programName}
                                               disabled
                                               className="form-control"
                                        />
                                        {formik.errors.programName && <div>{formik.errors.programName}</div>}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Date of birth</small>
                                        <input
                                            type="date"
                                            name="dob"
                                            onChange={formik.handleChange}
                                            value={formik.values.dob}
                                            className="form-control"
                                        />
                                        {formik.errors.dob && <div>{formik.errors.dob}</div>}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Allergies</small>
                                        <input
                                            type="text"
                                            name="allergies"
                                            onChange={formik.handleChange}
                                            value={formik.values.allergies}
                                            className="form-control"
                                        />
                                        {formik.errors.allergies && <div>{formik.errors.allergies}</div>}
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3 mb-3'>
                                    <div className='col-sm-3'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Teacher</small>
                                        <input type="text" name="teacher" onChange={formik.handleChange}
                                               value={formik.values.teacher}
                                               disabled
                                               className="form-control"
                                        />
                                        {formik.errors.teacher && <div>{formik.errors.teacher}</div>}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}> Center </small>
                                        <select
                                            className="form-control"
                                            name="center"
                                            value={selectedCenterId}
                                            disabled
                                            onChange={(event) => handleCenterChange(event.target.value)}
                                        >
                                            <option value="" disabled selected>Center</option>
                                            {user.center_list.map((id, index) => (
                                                <option key={index} value={id.id}>
                                                    {id.name}
                                                </option>
                                            ))}
                                        </select>
                                        {formik.touched.center && formik.errors.center && (
                                            <div className="text-red-500 text-sm">
                                                {formik.errors.center}
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}> Room </small>
                                        <select
                                            className="form-control"
                                            name="classroom"
                                            disabled
                                            {...formik.getFieldProps("classroom")}
                                        >
                                            <option value="">Room</option>
                                            {classRoomData.map((id, index, key) => (
                                                <option key={index} value={id._id}>
                                                    {id.name}
                                                </option>
                                            ))}
                                        </select>
                                        {formik.touched.room && formik.errors.room && (
                                            <div className="text-red-500 text-sm">
                                                {formik.errors.room}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{textAlign: 'center', marginTop: '20px'}}>
                                    <button type="submit" className="btn btn-success" disabled={loadBtn.one}>

                                        {loadBtn.one ? "Loading...." : "Edit"}
                                    </button>
                                </div>
                            </form>
                            <hr/>

                            <form onSubmit={formikParent.handleSubmit}>
                                <div className='row justify-content-between mt-3 mb-3 align-items-end'>
                                    <div className='col-sm-2'
                                         style={{display: "none", alignItems: "center", justifyContent: "center"}}>
                                        <label
                                            htmlFor="fileInput"

                                        >
                                            <img
                                                src={
                                                    profileImage
                                                        ? URL.createObjectURL(profileImage)
                                                        : "https://app.mickeytots.com/user.svg"
                                                }
                                                alt="Preview Box"
                                                className="rounded-circle"
                                                style={{width: "82px", height: "82px"}}
                                            />


                                            <input
                                                type="file"
                                                style={{display: "none"}}
                                                id="fileInput"
                                                name="profileImage"
                                                accept="image/gif, image/png, image/jpeg"

                                                // onChange={(e) => handleProfileImageUpload(e.target.files[0])}
                                            />
                                        </label>

                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Father's Name</small>
                                        <input type="text" name="fatherName" onChange={formikParent.handleChange}
                                               value={formikParent.values.fatherName}
                                               className="form-control"
                                        />
                                        {formikParent.errors.fatherName && <div>{formikParent.errors.fatherName}</div>}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Father's email</small>
                                        <input type="email" name="fatherEmail" onChange={formikParent.handleChange}
                                               value={formikParent.values.fatherEmail}
                                               className="form-control"
                                        />
                                        {formikParent.errors.fatherEmail &&
                                            <div>{formikParent.errors.fatherEmail}</div>}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Father's Phone:</small>
                                        <input type="tel" name="fatherPhone" onChange={formikParent.handleChange}
                                               value={formikParent.values.fatherPhone}
                                               className="form-control"
                                        />
                                        {formikParent.errors.fatherPhone &&
                                            <div>{formikParent.errors.fatherPhone}</div>}
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-5 mb-5 align-items-end'>
                                    <div className='col-sm-2'
                                         style={{display: "none", alignItems: "center", justifyContent: "center"}}>
                                        <label
                                            htmlFor="fileInput"

                                        >
                                            <img
                                                src={
                                                    profileImage
                                                        ? URL.createObjectURL(profileImage)
                                                        : "https://franchise.footprintseducation.in/assets/images/placeholder.jpg"
                                                }
                                                alt="Preview Box"
                                                className="rounded-circle"
                                                style={{width: "82px", height: "82px"}}
                                            />


                                            <input
                                                type="file"
                                                style={{display: "none"}}
                                                id="fileInput"
                                                name="profileImage"
                                                accept="image/gif, image/png, image/jpeg"

                                                // onChange={(e) => handleProfileImageUpload(e.target.files[0])}
                                            />
                                        </label>

                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Mother's Name:</small>
                                        <input type="text" name="motherName" onChange={formikParent.handleChange}
                                               value={formikParent.values.motherName}
                                               className="form-control"
                                        />
                                        {formikParent.errors.motherName && <div>{formikParent.errors.motherName}</div>}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Mother's Email</small>
                                        <input type="email" name="motherEmail" onChange={formikParent.handleChange}
                                               value={formikParent.values.motherEmail}

                                               className="form-control"/>
                                        {formikParent.errors.motherEmail &&
                                            <div>{formikParent.errors.motherEmail}</div>}
                                    </div>
                                    <div className='col-sm-4'>
                                        <small className="ml-2" style={{color: "#1876f2"}}>Mother's Phone:</small>
                                        <input type="tel" name="motherPhone" onChange={formikParent.handleChange}
                                               value={formikParent.values.motherPhone}
                                               className="form-control"
                                        />
                                        {formikParent.errors.motherPhone &&
                                            <div>{formikParent.errors.motherPhone}</div>}
                                    </div>
                                </div>


                                <div style={{
                                    textAlign: 'center',
                                    marginTop: '20px',

                                }}>
                                    <button type="submit" className="btn btn-success" disabled={loadBtn.two}>

                                        {loadBtn.two ? "Loading...." : "Edit"}
                                    </button>
                                </div>
                            </form>
                            <div style={{width: "100%", textAlign: "end", marginTop: "-30px"}}>
                                <button
                                    className="btn  btn-sm btn-primary"
                                    onClick={() => OpenAreYou({"item": parentId}, sendAccDetails, `Are your sure you want to edit the details  ?`)}
                                >
                                    {isLoadingSend ? "Loading................." : "Send Cred"}
                                </button>
                            </div>
                        </div>
                    )}
                </>
                {areYou.open ? (
                    <ConfirmationModal
                        CloseAreYou={CloseAreYou}
                        action={areYou.action}
                        message={areYou.message}
                        actionData={areYou}
                    />
                ) : null}
            </>
        </Modal>
    );
};

export default React.memo(ChildModal);



