import React, {createContext, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../Components/Loader";
import axiosInstance from "../utils/api";

const dataStructure = {
    isAuthenticated: false,
    type: null,
    user_id: null,
    user_data: [],
    center_list: [],
    program_list: [],
};

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const navigate = useNavigate();
    const [user, setUser] = useState(dataStructure);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCenter, setSelectedCenter] = useState({center_id: null, center_name: null});

    const errorModal = async (message, title) => {
        await Swal.fire({
            icon: "error",
            title: message ? message : "Oops...",
            text: title ? title : "Something went wrong, please contact with admin!",
        });
    };

    const successModal = async (message, title) => {
        await Swal.fire({
            icon: "success",
            title: message,
            text: title,
        });
    };

    const getCenterName = async () => {
        const foundCenter = user.center_list.find((cls) => cls._id === user.user_data.center_id);
        if (foundCenter) {
            return foundCenter.name
        }

    }

    const centersData = async (type) => {
        try {
            const responseCenters = await axiosInstance.get(`/center/`);
            let responseData = responseCenters.data;
            if (responseData.status === "success") {
                if (type === "init") {
                    return responseData.data;
                } else {
                    setUser((prevState) => {
                        if (JSON.stringify(prevState.center_list) !== JSON.stringify(responseData.data)) {
                            return {
                                ...prevState,
                                center_list: responseData.data,
                            };
                        } else {
                            return prevState;
                        }
                    });
                }
            } else {
                await errorModal();
            }
        } catch (error) {
            console.log("Error", error);
            await errorModal();
        }
    };
    const centerProgramData = async (type) => {
        try {
            const responseCenters = await axiosInstance.get(`/program/`);
            let responseData = responseCenters.data;
            if (responseData.status === "success") {
                if (type === "init") {
                    return responseData.data;
                } else {

                    setUser((prevState) => {
                        if (JSON.stringify(prevState.program_list) !== JSON.stringify(responseData.data)) {
                            return {
                                ...prevState,
                                program_list: responseData.data,
                            };
                        } else {
                            return prevState;
                        }
                    });
                }
            } else {
                await errorModal();
            }
        } catch (error) {
            console.log("Error", error);
            await errorModal();
        }
    };


    const panelSelectedCenter = (value) => {
        setSelectedCenter(value)
    }

    const getTeacherDetails = async (id, user_type, request_type) => {
        try {
            let endpoint1 = `/teacher/teacherById?id=${id}`;
            let endpoint2 = `/admin/adminById?id=${id}`;
            const response = await axiosInstance.get(
                user_type === "teacher" ? endpoint1 : endpoint2,
            );
            let responseData = response.data;
            if (responseData.status === "success") {
                if (request_type === "init") {
                    return responseData.data[0];
                } else {
                    setUser((prevState) => ({
                        prevState,
                        user_data: responseData.data[0],
                    }));
                }
            } else {
                await errorModal();
            }
        } catch (error) {
            console.log("Error", error);
            await errorModal();
        }
    };

    useEffect(() => {
        console.log("Entering useEffect");
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const token = JSON.parse(window.localStorage.getItem("userData"));
                const auth = token ? token.access_token : null;
                const type = token ? token.type : null;

                if (auth) {
                    const userData = await getTeacherDetails(token.user_id, type, "init");
                    const centers = await centersData("init");
                    const program = await centerProgramData("init");
                    const foundCenter = centers.find((cls) => cls._id === userData.center_id);
                    if (foundCenter) {
                        setSelectedCenter({center_id: userData.center_id, center_name: foundCenter.name})
                    }
                    if (userData && centers && program) {
                        setUser({
                            isAuthenticated: !!auth,
                            type: type,
                            user_id: token.user_id,
                            user_data: userData,
                            center_list: centers,
                            program_list: program,
                        });
                    }
                }

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log("Error", error);
                await errorModal()
            }
        };

        fetchData();
        console.log("Exiting useEffect");
    }, []);

    const loginUser = async (username, password, location) => {
        setIsLoading(true);
        const requestBody = {
            username: username,
            password: password,
        };
        try {
            const response = await axios.post(
                "https://app.mickeytots.com/api/auth/login/",
                requestBody,
            );
            const loginData = response.data;
            const auth = loginData ? loginData.access_token : null;
            const type = loginData ? loginData.type : null;
            window.localStorage.setItem("userData", JSON.stringify(loginData));
            const queryParams = new URLSearchParams(location.search);
            if (auth) {
                const userData = await getTeacherDetails(
                    loginData.user_id,
                    type,
                    "init",
                );
                const centers = await centersData("init");
                const program = await centerProgramData("init");
                const foundCenter = centers.find((cls) => cls._id === userData.center_id);
                if (foundCenter) {
                    setSelectedCenter({center_id: userData.center_id, center_name: foundCenter.name})
                }
                if (userData && centers && program) {
                    setUser({
                        isAuthenticated: !!auth,
                        type: type,
                        user_id: loginData.user_id,
                        user_data: userData,
                        center_list: centers,
                        program_list: program,
                    });
                }
            }

            const next = queryParams.get("next");
            if (next) {
                navigate(next);
            } else {
                navigate("/daily_update");
            }
        } catch (error) {
            setIsLoading(false);
            await errorModal();
        } finally {
            setIsLoading(false);
        }
    };

    const logoutUser = async () => {
        await window.localStorage.removeItem("userData");
        setUser(dataStructure)
        navigate("/login");
    };

    return (
        <>
            <AuthContext.Provider
                value={{
                    user,
                    loginUser,
                    logoutUser,
                    centersData,
                    centerProgramData,
                    getTeacherDetails,
                    errorModal,
                    getCenterName,
                    successModal,
                    panelSelectedCenter,
                    selectedCenter,
                    setSelectedCenter,
                }}
            >
                {isLoading ? <Loader/> : children}
            </AuthContext.Provider>
        </>
    );
}
