// import React, {useState, useEffect} from "react";
// import Modal from "react-modal";
// import axios from "axios";
// import "../../App.css";
// import {useAuth} from "../../auth/Auth";
// import SmallAdjustableLoader from "../../Components/SmallAdjustableLoader";
// import axiosInstance from "../../utils/api";
//
// const CreateBlogModal = ({isOpen, closeModal, editData, updateBlog}) => {
//     const {user, successModal, errorModal} = useAuth();
//     const [title, setTitle] = useState(null);
//     const [shortDescription, setShortDescription] = useState(null);
//     const [isActive, setIsActive] = useState(true);
//     const [timeToRead, setTimeToRead] = useState(null);
//     const [topic, setTopic] = useState(null);
//     const [id, setId] = useState(null);
//     const [loading, setLoading] = useState(false);
//
//     useEffect(() => {
//         if (editData) {
//             setTitle(editData.title);
//             setShortDescription(editData.short_description);
//             setIsActive(editData.is_active);
//             setTimeToRead(editData.time_to_read);
//             setTopic(editData.topic);
//             setId(editData.id)
//         } else {
//             setTitle("");
//             setShortDescription("");
//             setIsActive(true);
//             setTimeToRead("");
//             setTopic("");
//         }
//     }, [editData]);
//
//     const modalStyles = {
//         content: {
//             maxWidth: "50vw",
//             maxHeight: "80vh",
//             margin: "0 auto",
//             borderRadius: "16px",
//             overFlow: "auto",
//             top: "10%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center"
//         },
//     };
//
//     const handleCreateBlog = async (event) => {
//         event.preventDefault();
//         const formData = new FormData();
//         formData.append('title', title);
//         formData.append('short_description', shortDescription);
//         formData.append('is_active', isActive);
//         formData.append('uploaded_by', user.user_id);
//         formData.append('time_to_read', timeToRead);
//         formData.append('topic', topic);
//
//         try {
//             let endpoint1 = `/blog/updatePost?id=${id}`
//
//             let endpoint2 = "/blog/create/"
//
//             setLoading(true);
//             let response;
//             if (editData) {
//                 console.log("put")
//                 response = await axiosInstance.put(
//                     endpoint1,
//                     formData
//                 );
//                 let updater = response.data.data[0]
//                 updateBlog('edit', updater)
//             } else {
//                 console.log("create", formData)
//                 response = await axiosInstance.post(
//                     endpoint2,
//                     formData
//                 );
//                 let create = response.data.data[0]
//                 updateBlog('create', create)
//             }
//             let responseData = response.data;
//             console.log("responseData", responseData.data)
//             closeModal();
//             if (responseData.status === "success") {
//                 setLoading(false);
//                 successModal('Updated', 'Blog uploaded successfully!!')
//             } else {
//                 errorModal('Fail', 'Somthing went wrong!!')
//                 setLoading(false);
//             }
//         } catch (error) {
//             errorModal('Fail', 'Somthing went wrong!!')
//             setLoading(false);
//         }
//     };
//
//     return (
//         <Modal isOpen={isOpen} onRequestClose={closeModal} style={modalStyles} shouldCloseOnOverlayClick={false}>
//             {loading ? (
//                 <div style={{textAlign: "center"}}>
//                     <SmallAdjustableLoader/>
//                 </div>
//             ) : (
//                 <div className="modal-content">
//                     <div className="flex flex-row justify-between gap-4 w-full mb-5 border-b">
//                         <h1 className="text-2xl  p-2">{editData ? "Edit Blog" : "Create Blog"}</h1>
//                         <h1 onClick={closeModal} className="text-2xl p-2"
//                             style={{cursor: "pointer"}}>x</h1>
//                     </div>
//                     <form onSubmit={(event) => handleCreateBlog(event)}>
//                         <label>
//                             Title:
//                             <input
//                                 type="text"
//                                 value={title}
//                                 onChange={(e) => setTitle(e.target.value)}
//                                 required
//                             />
//                         </label>
//                         <label>
//                             Short Description:
//                             <textarea
//                                 value={shortDescription}
//                                 onChange={(e) => setShortDescription(e.target.value)}
//                                 required
//                             />
//                         </label>
//                         <label>
//                             Active:
//                             <input
//                                 type="checkbox"
//                                 checked={isActive}
//                                 onChange={(e) => setIsActive(e.target.checked)}
//                                 required
//                             />
//                         </label>
//                         <label>
//                             Time to Read:
//                             <input
//                                 type="text"
//                                 value={timeToRead}
//                                 onChange={(e) => setTimeToRead(e.target.value)}
//                                 required
//                             />
//                         </label>
//                         <label>
//                             Topic:
//                             <input
//                                 type="text"
//                                 value={topic}
//                                 onChange={(e) => setTopic(e.target.value)}
//                                 required
//                             />
//                         </label>
//                         <button type="submit" className="px-4 py-2 bg-[#4CAF50] text-white rounded-md ml-2">
//                             {editData ? "Update" : "Create"}
//                         </button>
//                     </form>
//                 </div>
//             )}
//         </Modal>
//     );
// };
//
// export default CreateBlogModal;


import React, {useState} from "react";
import {Modal, Form, Button} from "react-bootstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import SmallAdjustableLoader from "../../Components/SmallAdjustableLoader";
import axiosInstance from "../../utils/api";
import {useAuth} from "../../auth/Auth";

const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    shortDescription: Yup.string().required("Short Description is required"),
    timeToRead: Yup.string().required("Time to Read is required"),
    topic: Yup.string().required("Topic is required"),
});

const CreateBlogModal = ({isOpen, closeModal, editData, updateBlog}) => {
    const {user, successModal, errorModal} = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: editData?.title || "",
            shortDescription: editData ? editData.short_description : null,
            isActive: editData ? editData.is_active === 1 : true,
            timeToRead: editData ? editData.time_to_read : null,
            topic: editData ? editData.topic : null,
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true)
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('short_description', values.shortDescription);
            formData.append('is_active', values.isActive ? 1 : 0);
            formData.append('uploaded_by', user.user_id);
            formData.append('time_to_read', values.timeToRead);
            formData.append('topic', values.topic);
            try {
                let endpoint = editData
                    ? `/blog/updatePost?id=${editData.id}`
                    : "/blog/create/";

                console.log("endpoint", endpoint)
                let response;

                if (editData) {
                    response = await axiosInstance.put(endpoint, formData);
                } else {
                    response = await axiosInstance.post(endpoint, formData);
                }

                const responseData = response.data;

                if (responseData.status === "success") {
                    let updatedData;
                    if (editData) {
                        updatedData = responseData.data[0]
                    } else {
                        updatedData = responseData.data;
                    }
                    updateBlog(editData ? "edit" : "create", updatedData);
                    setIsLoading(false)
                    closeModal();
                    successModal("Updated", "Blog uploaded successfully!!");
                } else {
                    setIsLoading(false)
                    errorModal("Fail", "Something went wrong!!");
                }
            } catch (error) {
                setIsLoading(false)
                errorModal("Fail", "Something went wrong!!");
            }
        },
    });

    return (
        <Modal
            show={isOpen}
            onHide={closeModal}
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{editData ? "Edit Blog" : "Create Blog"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <div style={{textAlign: "center"}}>
                        <SmallAdjustableLoader/>
                    </div>
                ) : (
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                {...formik.getFieldProps("title")}
                                isInvalid={formik.touched.title && formik.errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="shortDescription">
                            <Form.Label>Short Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter short description"
                                rows={3}
                                {...formik.getFieldProps("shortDescription")}
                                isInvalid={
                                    formik.touched.shortDescription &&
                                    formik.errors.shortDescription
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.shortDescription}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="isActive">
                            <Form.Check
                                type="checkbox"
                                style={{alignItems: "center", display: "flex", gap: "15px"}}
                                label="Active"
                                {...formik.getFieldProps("isActive")}
                            />
                        </Form.Group>

                        <Form.Group controlId="timeToRead">
                            <Form.Label>Time to Read</Form.Label>


                            <Form.Select
                                {...formik.getFieldProps("timeToRead")}
                                isInvalid={formik.touched.timeToRead && formik.errors.timeToRead}
                            >
                                <option value="">--Select Time To Read--</option>
                                <option value="1 Min">1 Min</option>
                                <option value="2 Min">2 Min</option>
                                <option value="3 Min">3 Min</option>
                                <option value="4 Min">4 Min</option>
                                <option value="5 Min">5 Min</option>
                                <option value="6 Min">6 Min</option>
                                <option value="7 Min">7 Min</option>
                                <option value="8 Min">8 Min</option>
                                <option value="9 Min">9 Min</option>
                                <option value="10 Min">10 Min</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.topic}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="topic">
                            <Form.Label>Topic</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter a type"
                                {...formik.getFieldProps("topic")}
                                isInvalid={formik.touched.topic && formik.errors.topic}
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.topic}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className='row' style={{justifyContent: "center", marginTop: "50px"}}>
                            <Button variant="primary" type="submit" style={{width: "50%"}}>
                                {editData ? "Update" : "Create"}
                            </Button>
                        </div>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CreateBlogModal;

