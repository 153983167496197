import React, {useEffect} from "react";
import Header from "../Login/Header";
import JSMpeg from '@cycjimmy/jsmpeg-player';
import {websoket_host_port} from "../../env";

const LiveStreaming = () => {
    useEffect(() => {
        const canvas = document.getElementById('video-canvas');
        const url = `${websoket_host_port}/5101`;
        const player = new JSMpeg.Player(url, {canvas: canvas});

        return () => {
            player?.destroy();
        };
    }, []);

    return (
        <>
            <Header/>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <canvas id="video-canvas"></canvas>
            </div>
        </>
    );
};

export default LiveStreaming;
