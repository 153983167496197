import React, {useState} from 'react';
import {Box} from "@mui/material";
import Table from "react-bootstrap/Table";
import SmallAdjustableLoader from "../../Components/SmallAdjustableLoader";
import ChildModal from "../InputModals/UniverselModal";
import {Info} from "@mui/icons-material";

const TakeBreakTable = ({tableData, approveBreak, isLoadingTable, OpenAreYou}) => {
    const [childModal, setChildModal] = useState({open: false, data: null});
    const handleCloseModal = () => {
        setChildModal(
            {open: false, data: null}
        )
    }
    const handleChildModal = (id) => {
        setChildModal(
            {open: true, data: id}
        )
    }


    return (
        <>
            {childModal.open ? (
                <ChildModal
                    id={childModal}
                    handleCloseModal={handleCloseModal}
                />
            ) : null}
            {isLoadingTable ? (
                <SmallAdjustableLoader/>
            ) : (
                <div className='table_box'>
                    {tableData.length > 0 ? (
                        <Table striped bordered hover style={{minWidth: "100%"}}>
                            <thead
                                className="border-y-2 h-[40px] text-center bg-gray-500 text-white">
                            <tr>
                                <th>Child Name</th>
                                <th>Break From</th>
                                <th>Break Till</th>
                                <th>Reason</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300">
                            {tableData.map((announcement) => (
                                <tr key={announcement._id}>
                                    <td className="name_css"
                                        onClick={() => handleChildModal(announcement.child_id)}>{announcement.child?.name || 'NA'}</td>
                                    <td>
                                        {new Date(announcement.break_from * 1000).toLocaleDateString()}
                                    </td>
                                    <td>
                                        {new Date(announcement.break_till * 1000).toLocaleDateString()}
                                    </td>
                                    <td>{announcement.reason}</td>

                                    <td>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "2px",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <td className="capitalize"
                                                style={{gap: "20px", display: "flex", justifyContent: "center"}}>
                                                <button
                                                    disabled={announcement.is_approved === 0 ? false : announcement.is_approved === 1}
                                                    className={"btn btn-success btn-sm"}
                                                    onClick={() => OpenAreYou({
                                                        "id": announcement.id,
                                                        'type': 'approve'
                                                    }, approveBreak, 'Are You Sure you want to approve this request?')}
                                                >
                                                    {'Approve'}
                                                </button>
                                                <button
                                                    disabled={announcement.is_approved === 0 ? false : announcement.is_approved === -1}
                                                    className={"btn btn-danger btn-sm"}
                                                    onClick={() => OpenAreYou({
                                                            "id": announcement.id,
                                                            'type': 'reject'
                                                        }, approveBreak,
                                                        'Are You Sure you want to reject this request?')}
                                                >
                                                    {'Reject'}
                                                </button>
                                            </td>
                                        </Box>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="box_to_show">
                            <Info/>
                            No data available!
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default TakeBreakTable;
